/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';

import type { AgencyMenu, AgencyPreferencesCamel } from '../../api/models/agency';
import Agency from '../../api/models/agency';

type InitialState = {
  agency:
    | (InstanceType<typeof Agency> & { primaryMenu: AgencyMenu; footerMenu: AgencyMenu })
    | null;
  themePreferences: AgencyPreferencesCamel;
};

export const initialCompanyState: InitialState = {
  agency: null,
  themePreferences: {} as AgencyPreferencesCamel,
};

export const companySlice = createSlice({
  name: 'agency',
  initialState: initialCompanyState,
  reducers: {
    setThemePreferences(state, action) {
      state.themePreferences = action.payload;
    },
    setAgency(state, action) {
      state.agency = action.payload;
    },
  },
});

export const { setAgency, setThemePreferences } = companySlice.actions;

export const selectAgency = (state: { company: InitialState }) => state.company.agency;

export const selectThemePreferences = (state: { company: InitialState }) =>
  state.company.themePreferences;

export default companySlice.reducer;
