import { cmsImage } from '@homeflow/next/images';
import { ImageField } from '@homeflow/next/state';

export default function ImageBannerFixed({ image }: { image: ImageField }) {
  return (
    <div
      style={{
        backgroundImage: `url(${
          image.src?.startsWith('/images/') ? image.src : cmsImage(image.src)
        })`,
      }}
      className={`bg-fixed bg-cover h-full w-full`}
    ></div>
  );
}
